<template>
  <div class="page">
    <div class="header" id="navbar">
      <div class="navbar navbar-mobile d-flex justify-content-between d-md-none">
        <a href="/#/"><img class="logo d-md-none" alt="Logo" title="Logo" src="@/assets/pocketyard_logo.svg" /></a>
        <a href="/#/"><img class="logo d-none d-md-inline" alt="Logo" title="Logo" src="@/assets/pocketyard_logo_black.svg" /></a>
        <img class="hamburger" src="@/assets/Landing/hamburger.svg" @click="isSidebarOpen = true" />
      </div>
      <div class="navbar d-none d-md-flex">
        <a href="/#/"><img class="logo" alt="Logo" title="Logo" src="@/assets/pocketyard_logo_black.svg" /></a>
        <div class="buttons">
          <button class="btn login mr-3" @click="$router.push('login')">LOGIN</button>
          <button class="btn signup" @click="$router.push('register')">SIGN UP</button>
        </div>
      </div>
      <LandingSidebarVue :isOpen="isSidebarOpen" @close="isSidebarOpen = false" activeItem="buyers"/>
      <div class="hero">
        <div class="title">
          <h1 class="main"><img class="icon-vendor" src="@/assets/Landing/buyer-icon.svg" /> For Buyers</h1>
          <p class="sub">JOIN THE FUTURE OF CONSTRUCTION</p>
        </div>
        <div class="tagline">
          <h2 class="main">A Platform That Empowers You To Make Smart Purchasing Decisions.</h2>
        </div>
        <div class="button">
          <button class="btn buyer-button" @click="clickBuyNow">Buy Now <img src="@/assets/Landing/right-arrow.svg" /></button>
        </div>
        <div class="offset-container">
          <img src="@/assets/Landing/buyer-dashboard.png" class="offset-image">
        </div>
      </div>
    </div>

    <div class="body">
      <div class="list d-flex flex-column">
        <div class="item d-flex flex-column flex-md-row">
          <div class="texts">
            <h6 class="small-title">Discover</h6>
            <h3 class="title">Discover a Wide Range of Products and Save Money</h3>
            <p class="description">Pocketyard is not just a marketplace, it's a platform that empowers you to make smart purchasing decisions. You have the flexibility to either send out Request for Quotations (RFQs) to multiple vendors, receive bids, and compare prices, or simply purchase materials at a preset price. </p>
            <div class="bullets">
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>Unique features to select the most cost-effective option, ensure best value for money.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>Whether you're looking for the lowest bid or a quick purchase, Pocketyard has you covered.</p>
              </div>
            </div>
          </div>
          <div class="image-container">
            <img src="@/assets/Landing/buyer-item-1.png" class="item-image d-none d-md-block">
            <img src="@/assets/Landing/buyer-item-1-mobile.png" class="item-image d-md-none">
          </div>
        </div>
        <div class="item d-flex flex-column flex-md-row-reverse">
          <div class="texts texts-right">
            <h6 class="small-title">Transactions</h6>
            <h3 class="title">Transparent Transactions for Peace of Mind</h3>
            <p class="description">Every transaction is clear and straightforward. Our platform provides a detailed record of your orders, including the vendor details, product specifications, pricing, and status updates.</p>
            <div class="bullets">
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>Transparency extends to communication with vendors, ensuring all queries and discussions.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>This level of transparency not only simplifies procurement process but also provides the confidence and peace of mind.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>With Pocketyard, you're always in control of your procurement process.</p>
              </div>
            </div>
          </div>
          <div class="image-container">
            <img src="@/assets/Landing/buyer-item-2.png" class="item-image d-none d-md-block">
            <img src="@/assets/Landing/buyer-item-2-mobile.png" class="item-image d-md-none">
          </div>
        </div>
        <div class="item d-flex flex-column flex-md-row">
          <div class="texts">
            <h6 class="small-title">Future</h6>
            <h3 class="title">Join the Future of Construction Material Procurement</h3>
            <p class="description">The traditional process of procuring construction materials can be lengthy and cumbersome, often involving numerous phone calls to different vendors to check availability and compare prices. </p>
            <div class="bullets">
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>Delays in securing materials can even lead to costly project setbacks.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>Our platform brings together a wide range of vendors in one place, allowing you to check availability, compare prices, and place orders with just a few clicks.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>Reduces the risk of project delays due to material shortages.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>No more endless phone calls or back-and-forth emails - with Pocketyard, you can focus on what really matters: building and creating.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon" />
                <p>Register with Pocketyard and transform the way you manage your construction needs.</p>
              </div>
            </div>
          </div>
          <div class="image-container">
            <img src="@/assets/Landing/buyer-item-3.png" class="item-image d-none d-md-block">
            <img src="@/assets/Landing/buyer-item-3-mobile.png" class="item-image d-md-none">
          </div>
        </div>
      </div>
    </div>

    <LandingFooter />
  </div>
</template>

<script>
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import LandingSidebarVue from '@/shared/elements/LandingSidebar.vue';
import LandingFooter from '@/shared/elements/LandingFooter.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Landing',

  mixins: [_appApiHelper],

  components: {
    LandingSidebarVue,
    LandingFooter,
  },

  data() {
    return {
      isSidebarOpen: false,
    }
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    clickBuyNow() {
      if (this.user) {
        this.$router.push({ name: 'Dashboard' });
      } else {
        this.$router.push({ name: 'Login' });
      }
    },
    scrollToTop() {
      document.getElementById('navbar').scrollIntoView({ behavior: 'smooth' })
    },
    scrollToDetails() {
      document.getElementById('details').scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>@import '@/scss/theme/_breakpoints';
@import '@/scss/custom/vendor';</style>